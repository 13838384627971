import React, { Fragment } from 'react'
import cleaningImage from './relax.jpg' // Replace with the appropriate cleaning-related image
import PropTypes from 'prop-types'

import './contact10.css'

const Contact10 = (props) => {
  return (
    <div className="contact10-container1 thq-section-padding">
      <div className="contact10-max-width thq-section-max-width">
        <div className="contact10-content1 thq-flex-row">
          <div className="contact10-content2">
            <h2 className="thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="contact10-text18">Contactez-nous</span>
                </Fragment>
              )}
            </h2>
            <p className="thq-body-large">
              {props.content1 ?? (
                <Fragment>
                  <span className="contact10-text16">
                    Nous sommes à votre service pour tout besoin de nettoyage résidentiel ou professionnel, garantissant des espaces impeccables et un environnement sain.
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="contact10-content3 thq-flex-row">
          <div className="contact10-container2">
            <img
              alt={props.location1ImageAlt}
              src={props.location1ImageSrc}
              className="contact10-image1 thq-img-ratio-16-9"
            />
            <h3 className="contact10-text12 thq-heading-3">
              {props.location1 ?? (
                <Fragment>
                  <span className="contact10-text20">Nos Services de Nettoyage</span>
                </Fragment>
              )}
            </h3>
            <p className="thq-body-large">
              {props.location1Description ?? (
                <Fragment>
                  <span className="contact10-text17">
                    Nous proposons des services de nettoyage de qualité supérieure, adaptés aux besoins résidentiels et commerciaux, en mettant l'accent sur la propreté et le bien-être.
                  </span>
                </Fragment>
              )}
            </p>
            <div className="contact10-container3">
              {/* Additional content can go here */}
            </div>
          </div>
          <div className="contact10-container4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d6697.700615935461!2d10.453228354531978!3d32.928552978664605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenue%20Farhat%20Hached%20Tataouine%203200%20%2C%20Tunisie%2C%20Tataouine%2C%20Tunisia!5e0!3m2!1sar!2stn!4v1732096524751!5m2!1sar!2stn"
              width="600"
              height="375"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
            <h3 className="contact10-text14 thq-heading-3">
              {props.location2 ?? (
                <Fragment>
                  <span className="contact10-text21">Nos Emplacements</span>
                </Fragment>
              )}
            </h3>
            <p className="thq-body-large">
              {props.location2Description ?? (
                <Fragment>
                  <span className="contact10-text19">
                    Nous intervenons dans plusieurs localités pour répondre à vos besoins de nettoyage, que ce soit chez vous ou dans vos locaux professionnels.
                  </span>
                </Fragment>
              )}
            </p>
            <div className="contact10-container5">
              <a
                href="https://maps.app.goo.gl/EkavJhooiW64BMWz7"
                target="_blank"
                rel="noreferrer noopener"
                className="thq-button-flat thq-body-small"
              >
                Directions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Contact10.defaultProps = {
  content1: undefined,
  location2ImageSrc:
    'https://images.unsplash.com/photo-1669312716490-5766f391d69b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTA3MTQ1M3w&ixlib=rb-4.0.3&q=80&w=1080',
  location1ImageSrc:
    cleaningImage,
  location1Description: undefined,
  location2ImageAlt: "Image de la carte montrant l'emplacement",
  heading1: undefined,
  location2Description: undefined,
  location1ImageAlt: "Image illustrant un espace propre",
  location1: undefined,
  location2: undefined,
}

Contact10.propTypes = {
  content1: PropTypes.element,
  location2ImageSrc: PropTypes.string,
  location1ImageSrc: PropTypes.string,
  location1Description: PropTypes.element,
  location2ImageAlt: PropTypes.string,
  heading1: PropTypes.element,
  location2Description: PropTypes.element,
  location1ImageAlt: PropTypes.string,
  location1: PropTypes.element,
  location2: PropTypes.element,
}

export default Contact10
