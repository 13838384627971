import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ServicesPage from './components/Service';  
import ContactPage from './components/Contacter'; 
import EquipePage from './components/Equipement.'; 
import ReserverPage from './components/Reserver';// This path needs to be correct
 // Import your Service component
import Home from './views/home';  // Import your Home component
import NotFound from './views/not-found';  // Import your NotFound component
import './style.css';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Define your routes using the 'element' prop */}
        <Route path="/" element={<Home />} />
        <Route path="/Equipement" element={<EquipePage />} />
        <Route path="/Service" element={<ServicesPage />} />
        <Route path="/Contacter" element={<ContactPage />} />
        <Route path="/Reserver" element={<ReserverPage />} />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
