import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Navbar8 from '../components/navbar8';
import Footer4 from '../components/footer4';
import '../views/home.css';
import './Service.css';

// Ensure you have images in the proper directory
import icone_nettoyage_industriel from './factory.png';
import icone_gestion_dechets from './bin.png';
import icone_decontamination_sol_eau from './waterdrop.png';

const Service = () => {
  const services = [
   
    {
      icon: icone_nettoyage_industriel,
      title: "Nettoyage Industriel",
      description: `Nous offrons des services de nettoyage industriel spécialisés, en utilisant des équipements modernes pour garantir une propreté optimale des sites industriels. Nous intervenons dans divers secteurs, notamment les usines, les chantiers de construction et les installations de production.`
    },
    {
      icon: icone_gestion_dechets,
      title: "Gestion des Déchets",
      description: `Notre service de gestion des déchets comprend la collecte, le tri et le traitement des déchets industriels et commerciaux. Nous proposons des solutions personnalisées pour répondre aux besoins spécifiques de chaque client tout en respectant les normes environnementales.`
    },
    {
      icon: icone_decontamination_sol_eau,
      title: "Décontamination Sol et Eau",
      description: `Nous proposons des services de décontamination des sols et des eaux, en utilisant des technologies avancées pour éliminer les contaminants et restaurer la qualité environnementale. Nous intervenons rapidement et efficacement pour protéger les ressources naturelles.`
    },
  ];

  return (
    <div className="service-container">
      <Helmet>
        <title>TRC-Service</title>
        <meta property="og:title" content="Nos Services - Atlas Foyer" />
      </Helmet>

      {/* Navbar */}
      <Navbar8
        page4Description={<span className="home-text100">Contactez-nous pour toute question ou réservation.</span>}
        link3={<Link to="/Réserver" className="navbar8-text16">Réserver Maintenant</Link>}
        link2={<Link to="/" className="navbar8-text16">Accueil</Link>}
        page1={<span className="home-text103">Accueil</span>}
        link1={<Fragment></Fragment>}
        page4={<span className="home-text105">Contact</span>}
        page2={<Link to="/" className="navbar8-text16">Accueil</Link>}
      />

      {/* Service Intro */}
      <section className="service-intro">
        <div className="container">
          <div className="text-content">
            <h1>Nos Services</h1>
            <p>
              Bienvenue à Best Clean
            </p>
            <p>
              Nous offrons des services exceptionnels pour vous assurer un environnement Stable.
            </p>
          </div>
        </div>
      </section>

      {/* Services List */}
      <section className="services-list">
        <div className="container">
          <ul>
            {services.map((service, index) => (
              <li key={index} className="service-item">
                <img
                  src={service.icon}
                  alt={`Icon for ${service.title}`}
                  className="service-icon"
                />
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Footer */}
      <Footer4 />
    </div>
  );
};

export default Service;
