import React from 'react';

import Navbar8 from '../components/navbar8';
import Footer4 from '../components/footer4';
import '../views/home.css';
import './Equipement.css';

// Importing images
import camionAmpliroll from './camion_ampliroll.png';
import camionAspiration from './camion_aspiration.png';
import benneTasseuse from './benne_tasseuse.png';
import miniBom from './mini_bom.png';
import balayeuseAspiratrice from './balayeuse_aspiratrice.png';
import benneBasculante from './benne_basculante.png';
import caissonCielOuvert from './caisson_ciel_ouvert.png';
import conteneursCorbeilles from './conteneurs_corbeilles.png';
import tractopelle from './tractopelle.png';
import niveleuse from './niveleuse.png';

const Equipement = () => {
  const equipments = [
    {
      title: "Camion Ampliroll :",
      description: "Bras de manutention coulissants et articulés avec une capacité de 26T.",
      image: camionAmpliroll,
    },
    {
      title: "Camion d’Aspiration :",
      description: "Nettoie égouts et enlève pollution grâce à un réservoir haute pression.",
      image: camionAspiration,
    },
    {
      title: "Camions à Benne Tasseuse :",
      description: "Collecte et compresse les ordures, équipé d’un lève-conteneurs universel.",
      image: benneTasseuse,
    },
    {
      title: "Benne à Ordures Mini :",
      description: "Capacité de 7 m³ avec un système de compaction et déchargement arrière.",
      image: miniBom,
    },
    {
      title: "Balayeuse Aspiratrice :",
      description: "Nettoie espaces publics avec haute pression et commandes ergonomiques.",
      image: balayeuseAspiratrice,
    },
    {
      title: "Benne Basculante :",
      description: "Transporte matériaux en vrac avec vidage rapide et sécurité renforcée.",
      image: benneBasculante,
    },
    {
      title: "Caissons à Ciel Ouvert :",
      description: "Pour produits en vrac jusqu’à 30 m³, avec verrouillage hydraulique.",
      image: caissonCielOuvert,
    },
    {
      title: "Conteneurs et Corbeilles :",
      description: "Polyéthylène haute densité, résistant avec manipulation confortable.",
      image: conteneursCorbeilles,
    },
    {
      title: "Tractopelle :",
      description: "Engin polyvalent pour creusement de tranchées et ramassage.",
      image: tractopelle,
    },
    {
      title: "Niveleuse :",
      description: "Équipe d’une lame pour talutage et réglage de matériaux.",
      image: niveleuse,
    },
  ];

  return (
    <div className="equipement-container">
      {/* Navbar */}
      <Navbar8 />

      {/* Equipment Intro */}
      <section className="equipement-intro">
        <div className="container">
          <h1>
            Nos Équipements<br />
            <span className="description">Découvrez notre gamme d'équipements modernes et performants.</span>
          </h1>
        </div>
      </section>

      {/* Equipment List */}
      <section className="equipments-list">
        <div className="container">
          <ul>
            {equipments.map((equipment, index) => (
              <li key={index} className="equipement-item">
                <img src={equipment.image} alt={equipment.title} className="equipement-icon" />
                <h3>{equipment.title}</h3>
                <p>{equipment.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Footer */}
      <Footer4 />
    </div>
  );
};

export default Equipement;
