import React from 'react';
import './testimonial17.css'; // Make sure to add the corresponding CSS file
import mainImage from './ref1.png';
import ref1 from './ref2.png';
import ref2 from './ref3.png';
import cert1 from './cert1.jpg'; // Add the new image
import cert2 from './cert2.jpg'; // Add the new image

const Testimonial17 = () => {
  return (
    <div className="testimonial17-container">
      {/* Nos Certificat Section */}
      <div className="testimonial17-certificat">
        <h2 className="testimonial17-title">Nos Certificats</h2> {/* Fixed text for Nos Certificat */}
        <div className="testimonial17-certificat-images">
          <div className="testimonial17-certificat-image">
            <img src={cert2} alt="Certificate 1" />
          </div>
          <div className="testimonial17-certificat-image">
            <img src={cert1} alt="Certificate 2" />
          </div>
        </div>
      </div>

      {/* Nos Références Section */}
      <div className="testimonial17-title">Nos Références</div> {/* Fixed text for Nos Références */}
      <div className="testimonial17-images">
        <div className="testimonial17-main-image">
          <img src={mainImage} alt="Main Reference" />
        </div>
      </div>
    </div>
  );
};

export default Testimonial17;
