import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Navbar8 from '../components/navbar8';
import Hero17 from '../components/hero17';
import Features24 from '../components/features24';
import CTA26 from '../components/cta26';
import Features25 from '../components/features25';
import Pricing14 from '../components/pricing14';
import Steps2 from '../components/steps2';
import Contact10 from '../components/contact10';
import Footer4 from '../components/footer4';
import Testimonial17 from '../components/testimonial17'; // Import the Testimonial17 component
import './home.css';

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Best Clean Environment</title>
        <meta property="og:title" content="Best Clean Environment" />
      </Helmet>
      <Navbar8
        page4Description={
          <Fragment>
            <span className="home-text100">Contactez-nous pour toute question ou service.</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text103">Accueil</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <Link to="/Contacter" className="navbar8-text16">Contacter</Link>
          </Fragment>
        }
      ></Navbar8>
      
      <Hero17
        action1={
          <Fragment>
            <Link to="/Service" className="navbar8-text16">Nos Services</Link>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text116">Best Clean Environment</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text117">
              Nous fournissons des solutions modernes pour la gestion des déchets et la protection de l'environnement, en utilisant des équipements de pointe et des services adaptés.
            </span>
          </Fragment>
        }
      ></Hero17>
      
      
      
      <Features24
        feature1Title={
          <Fragment>
            <span className="home-text121">Gestion des déchets efficace</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text123">Technologies de nettoyage avancées</span>
          </Fragment>
        }
      ></Features24>

      <CTA26
        heading1={
          <Fragment>
            <span className="home-text124">Best Clean Environment</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text125">
              Découvrez nos services de gestion des déchets et de nettoyage industriels, contactez-nous pour plus d'informations.
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <Link to="/Equipement" className="navbar8-text16">Nos Equipements</Link>
          </Fragment>
        }
      ></CTA26>

      <Features25
        feature3Description={
          <Fragment>
            <span className="home-text127">
              Des solutions écologiques pour la décontamination des sols et des eaux.
            </span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text128">
              Des équipements modernes pour une performance optimale dans le nettoyage industriel.
            </span>
          </Fragment>
        }
      ></Features25>

      <Pricing14
        plan3Price={<Fragment><span className="home-text133">$49/mo</span></Fragment>}
        plan1Price1={<Fragment><span className="home-text151">$200/an</span></Fragment>}
      ></Pricing14>

      <Steps2
        step2Description={
          <Fragment>
            <span className="home-text164">Notre processus est simple et transparent.</span>
          </Fragment>
        }
      ></Steps2>
<Testimonial17
        title="Nos Références"
        images={{
          main: '/path/to/main/image.jpg', // Replace with your actual path
          ref1: '/path/to/ref1.png',       // Replace with your actual path
          ref2: '/path/to/ref2.png',       // Replace with your actual path
        }}
      />
      <Contact10
        description={
          <Fragment>
            <span className="home-text168">
              Contactez-nous pour plus d'informations ou une consultation gratuite.
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <Link to="/Contacter" className="navbar8-text16">Nous contacter</Link>
          </Fragment>
        }
      ></Contact10>

      <Footer4></Footer4>
    </div>
  );
};

export default Home;
